@import url('https://fonts.maateen.me/solaiman-lipi/font.css');

.container {
  margin: 0px 20%;
  border: 2px solid #fcfcf2;
  box-shadow: -6px 3px 21px -5px #bbb;
  /* width: 100vh; */
}

.header {
  background: #f3c7ac;
  padding: 10px 0px;
  font-family: Arial;
  text-align: center;
  width: 60%;
  border-radius: 50px;
  box-shadow: -6px 3px 21px -5px #bbb;
  border: 2px solid rgba(229, 243, 211, 0.6);
}

.decorated {
  display: flex;
  width: 100%;
  margin: 5px 0px;
  border: none;
  border-right: 10px solid transparent;
  background: #f3c7ac;
  padding: 10px 10px;
  font-family: Arial;
  text-align: center;
  border-radius: 30px;
}

.decorated option {
  margin-top: 40px;
  background: rgba(0, 0, 0, 0.3);
  color: #050505;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  border: none;
  outline: none;
}

.form {
  width: 60%;
}

.user-info-block {
  display: flex;
  width: 100%;
  margin: 5px 0px;
  box-shadow: -6px 3px 21px -5px;
  border-right: 5px solid #e5f3d3;
  /* border-start-end-radius: 10px;
    border-end-start-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; */
}

.name-field {
  background: #f3c7ac;
  width: 40%;
  padding: 5px;
}

.input-field {
  background: #eaf3ac2f;
  width: 60%;
  border: none;
  outline: none;
  padding: 5px 8px;
  appearance: unset;
}

.text-beautify {
  font-size: 15px;
  font-weight: 500;
  text-shadow: 1px 2px 7px #04040416;
  font-family: 'SolaimanLipi', sans-serif;
}


/* modal styles */
body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #07a58b;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
}

.close-modal {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px 5px;
}

@media (max-width: 550px) {
  .container {
    margin: 0px;
    box-shadow: none;
  }

  .form {
    width: 95%;
  }

  .user-info-block {
    margin: 10px 0px;
    border-right: none;
  }

  .input-field {
    padding: 8px 8px;
    font-size: 16px;
  }
}